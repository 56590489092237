import React from "react"
import Asterisk from "../../../../svg/asterisk.inline.svg"
import Arrow from "../../../../svg/arrow.inline.svg"
import { Link } from "gatsby"
import he from "he"
import { transformImage } from "../../../../gatsby/transformers/imageTransformer"
import { screenSizes, UrlImage } from "@social-supermarket/social-supermarket-components"
import UnderlineHeader from "../../title/UnderlineHeader"
import styled from "styled-components"
import { safeHeDecode } from "../../../util/generalUtil"

const Container = styled.div`
  width: 100%;
  position: relative;
  height: 500px;
  margin: 0;
`

const ImageContainer = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0;
`

const Content = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 7% 7%;
  background-color: hsla(0, 0%, 0%, 0.3);
  transition: opacity 0.3s ease-in-out;
  transition-delay: 0.3s;
  opacity: ${({ hide }) => (hide ? "0" : "1")};
`

const Caption = styled.div`
  display: flex;
  align-items: flex-start;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);

  h3 {
    font-size: 2.2em;
    color: white;
  }

  path {
    fill: white;
  }
`
const AsteriskContainer = styled.div`
  svg {
    margin-left: 0;
    width: 20px;
  }
`
const Title = styled.div`
  height: 300px;
  display: flex;
  align-items: center;

  h1 {
    color: white;
    font-size: 2.2em;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);

    @media (max-width: ${screenSizes.tablet}px) {
      font-size: 2em;
    }

    svg {
      width: 35px;
      margin-right: 10px;
      display: inline-block;
      position: relative;
      bottom: 7px;
    }
  }
`

const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);

  svg {
    width: 50px;
    margin-right: 10px;
    display: inline-block;
  }

  a {
    cursor: pointer;
    color: white;
    font-size: 0.9em;
    text-transform: uppercase;
    text-decoration: underline;
  }
`

const HeroFeature = ({ feature: { title, link, caption, colour, image }, hideText }) => {
  return (
    <Container>
      {image && (
        <ImageContainer>
          <UrlImage
            image={transformImage(image, title)}
            objectFit={"cover"}
            sizes="1200px"
            style={{ width: "100%", height: "100%" }}
          />
        </ImageContainer>
      )}

      <Content hide={hideText}>
        <Caption>
          <UnderlineHeader>{caption}</UnderlineHeader>
          <AsteriskContainer>
            <Asterisk />
          </AsteriskContainer>
        </Caption>
        <Title>
          <h1>
            <Asterisk style={{ fill: colour }} />
            {title}
          </h1>
        </Title>
        {link && (
          <LinkContainer>
            <Arrow style={{ fill: colour }} />
            <Link to={link.url}>{safeHeDecode(link.title || "")}</Link>
          </LinkContainer>
        )}
      </Content>
    </Container>
  )
}

export default HeroFeature
