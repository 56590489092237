import React, { FC } from "react"
import VideoEmbed from "../../../../components/layout/video-embed/VideoEmbed"
import { GatsbyImage } from "gatsby-plugin-image"
import UnderlineIconLink from "../../title/UnderlineIconLink"
import { screenSizes } from "@social-supermarket/social-supermarket-components"
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  width: 100%;
  margin: auto;
  justify-content: center;
  max-width: 1100px;

  @media (max-width: ${screenSizes.tablet}px) {
    flex-direction: column;
    padding: 40px 20px;
  }

  h4 {
    font-family: "Minion", "Times New Roman", serif;
    font-size: 2em;
    color: hsla(0, 0%, 0%, 0.8);
    text-transform: none;
    font-weight: normal;
    margin-bottom: 30px;
  }
`

const Left = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  order: ${({ flip }) => (flip ? "10" : "unset")};

  @media (max-width: ${screenSizes.tablet}px) {
    width: 100%;
    margin-bottom: ${({ flip }) => (flip ? "0" : "40px")};
  }

  img {
    margin: 0;
  }

  iframe {
    margin: 0;
  }
`
const Right = styled.div`
  flex: 1;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    margin-bottom: 40px;
  }

  @media (max-width: ${screenSizes.tablet}px) {
    padding: 0;
    margin-bottom: ${({ flip }) => (flip ? "40px" : "0")};
  }
`

interface Props {
  title: string
  fill: string
  videoUrl?: string
  imageUrl?: string
  description?: string
  linkText?: string
  linkTo?: string
  external?: boolean
  left?: boolean
  fluid?: any
}

const MajorFeature: FC<Props> = ({
  title,
  fill,
  videoUrl,
  imageUrl,
  description,
  linkText,
  linkTo,
  external,
  left,
  fluid,
}) => {
  return (
    <Container>
      <Left flip={left}>
        {videoUrl && <VideoEmbed url={videoUrl} />}
        {imageUrl && <img src={imageUrl} alt={title} />}
        {fluid && <GatsbyImage image={fluid} alt={title} />}
      </Left>
      <Right flip={left}>
        <h4 dangerouslySetInnerHTML={{ __html: title }} />
        {description && <p>{description}</p>}
        {linkTo && (
          <UnderlineIconLink url={linkTo} label={linkText} fill={fill} external={external} />
        )}
      </Right>
    </Container>
  )
}

export default MajorFeature
