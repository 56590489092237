import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import MajorFeature from "./MajorFeature"

const HomeMajorFeature = ({ index }) => {
  const feature = useStaticQuery(queryString).allWp.nodes[0].options.custom.features[index]
  return (
    <MajorFeature
      title={feature.title}
      fill={feature.color}
      fluid={feature.image.localFile.childImageSharp.gatsbyImageData}
      linkText={feature.link.title}
      linkTo={feature.link.url}
    />
  )
}

export default HomeMajorFeature

const queryString = graphql`
  {
    allWp {
      nodes {
        options {
          custom {
            features {
              title
              color
              link {
                title
                url
              }
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
