import React from "react"
import * as style from "./Radiation.module.scss"
import { Link } from "gatsby"
import RadiationLines from "../../../svg/radiation.inline.svg"
import Zoom from "react-reveal/Zoom"
import { Fade } from "react-reveal"

const Radiation = () => {
  return (
    <div className={style.radiation}>
      <Fade delay={500}>
        <h3>Net Positive Impact</h3>
        <p>
          Instead of offsetting and counteracting, every social enterprise we work with puts more
          back into communities and the planet than it takes to create their products.
        </p>
        <Link to={"about"}>Learn More</Link>
      </Fade>
      <Zoom>
        <div className={style.radiationLines}>
          <RadiationLines />
        </div>
      </Zoom>
    </div>
  )
}

export default Radiation
